<template>
  <div class="mobileScreen-main-container">
    <div class="relativeclass d-flex justify-content-center h-100 flex-column">
      <div
        style="height: 90%;"
        class="d-flex align-items-center justify-content-center flex-column"
      >
        <div class="mb-4">
          <img src="../../assets/mobile-newUI-imgs/mobile-logo.svg" alt="" />
        </div>

        <div class="new-internetContainer">
          <div v-if="isNetSpeed && checkupfinish" class="check-int-font"> 
            Your internet speed is stable. Click “Next” to proceed with the test</div>

          <div v-else class="check-int-font">Checking your internet speed.</div>

          <div class="relativeclass w-100 d-flex justify-content-center">
            <div class="progress mt-5 ">
              <div class="barOverflow">
                <div
                  class="bar"
                  ref="bar"
                  :class="
                    checkupfinish && isNetSpeed ? 'green-bar' : 'blue-bar'
                  "
                ></div>
              </div>
            </div>
            <div class="fix-download-class">
              <div class="download-font">Download</div>
              <div class="mbps-numb-font">
                {{ downloadSpeed }}
              </div>
              <div class="mbps-font">
                mbps
              </div>
            </div>
          </div>

          <div
            class="tracking-div d-flex align-items-center justify-content-between"
          >
            <div>
              <div class="upload-txt">
                Upload mbps
              </div>
              <div class="upload-bluetxt">
                {{ uploadSpeed }}
              </div>
              <!-- <div class="upload-blacktxt">
                --
              </div> -->
            </div>
            <div>
              <img src="../../assets/mobile-newUI-imgs/new-line.svg" alt="" />
            </div>
            <div>
              <div class="upload-txt">
                Download mbps
              </div>
              <div class="upload-greentxt">
                {{ downloadSpeed }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="isNetSpeed && checkupfinish"
          @click="next()"
          class="d-flex justify-content-center mt-5"
          style="width: 50%;"
        >
          <b-button class="mobileScreen-startBtn w-100">Next </b-button>
        </div>
      </div>
      <div
        class="copyrights-font d-flex align-items-end justify-content-center mb-3"
        style="height: 10%;"
      >
        Copyrights &copy; 2024. All rights reserved
      </div>
    </div>
    <div v-if="!isNetSpeed && slowInternetModal && bothFetched" class="testscreen-custom-modal">
      <div class="mobile-testscreen-modal-content">
        <div
          class="d-flex align-items-center justify-content-center flex-column"
        >
          <div>
            <img
              src="../../assets/mobile-newUI-imgs/new-warning-icon.svg"
              alt=""
            />
          </div>

          <div class="mt-4 mobileScreen-modal-head">
            Alert!
          </div>

          <div class="mt-3 mobileScreen-modal-subhead text-center">
            Due to a slow internet connection, this test
                              <br />
                              cannot be taken. Please try with proper <br/> internet connection.
          </div>

          <div
            class="mt-1 bluebtns d-flex align-items-center justify-content-center flex-column w-100"
          >
           
              <b-button
                @click="(slowInternetModal=false)"
                class="mobileScreen-startBtn mt-3 w-50"
                >Okay</b-button
              >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import internet from "../../mixins/internet";
export default {
  name: "MobileNewInternetCheck",
  data() {
    return {
      progress: { percentage: 0 },
      slowInternetModal:true,
    };
  },
  mixins: [internet],
  created() {
    this.animateProgress();
  },
  watch: {
    downloadSpeed(newSpeed) {
      if (newSpeed) {
        this.animateProgress();
      }
    },
  },
  methods: {
    animateProgress() {
      let targetPercentage = (this.downloadSpeed / 400) * 100; // Scale downloadSpeed to 0-100 range
      let start = { p: 0 };
      let duration = 2000;

      let animate = (timestamp) => {
        if (!start.timestamp) start.timestamp = timestamp;
        let progressTime = timestamp - start.timestamp;
        let progressValue = Math.min(progressTime / duration, 1);
        let easedProgress = this.easeInOutQuad(progressValue); // Custom easing function
        let oscillation = Math.sin(progressValue * Math.PI); // Oscillation effect
        let p =
          easedProgress * targetPercentage * (1 - Math.abs(oscillation * 0.1));
        this.$refs.bar.style.transform = "rotate(" + (70 + p * 1.8) + "deg)";
        this.progress.percentage = Math.floor(p);
        if (progressValue < 1) {
          window.requestAnimationFrame(animate);
        }
      };

      window.requestAnimationFrame(animate);
    },
    easeInOutQuad(t) {
      // Custom easing function for smooth animation
      // return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      return t < 0.5 ? 2 * t * t : -1 + (4 - 1.5 * t) * t;
    },
  },
};
</script>

<style scoped>
.new-internetContainer {
  background: #f8faff;
  box-shadow: 0px 15px 30px rgba(122, 122, 122, 0.2);
  border-radius: 14px;
  padding: 20px;
  text-align: center;
  /* height: 60%; */
  width: 92%;
}

.check-int-font {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #20639b;
}

.fix-download-class {
  position: absolute;
  top: 96px;
}

.download-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #272525;
}

.mbps-numb-font {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  text-align: center;
  color: #8c8c8c;
}

.mbps-font {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #8c8c8c;
}

.tracking-div {
  padding: 10px 20px;
  background: rgba(102, 112, 133, 0.15);
  border-radius: 6px;
  padding: 10px;
}

.upload-txt {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #1e2022;
}

.upload-bluetxt {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #20639b;
}

.upload-greentxt {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #009109;
}

.upload-blacktxt {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.progress {
  position: relative;
  margin: 4px;
  text-align: center;
  background: transparent;
  height: 230px;
  width: 278px; /* Adjust this width to match your design */
}
.barOverflow {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 76%; /* Half circle */
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 274px;
  height: 275px; /* Full circle */
  border-radius: 50%;
}
.green-bar {
  border: 25px solid #eee;
  border-bottom-color: green;
  border-right-color: green;
}
.blue-bar {
  border: 25px solid #eee;
  border-bottom-color: #0bf;
  border-right-color: #0bf;
}
.testscreen-custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background overlay */
  z-index: 1500;
}
.mobile-testscreen-modal-content {
    padding: 20px 10px 20px 10px;
    width: 92%;
    border-radius: 12px;
    background: #fff !important;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08) !important;
    position: relative;
}

</style>
